import Module from './Module';
import MethodNames from '../utils/MethodNames';
import ModuleNames from '../utils/ModuleNames';
import ErrorCode from '../ErrorCode';

const moods = ['neutral', 'positive', 'negative', 'acknowledge', 'thinking'];
// Animation controls
export default class AcModule extends Module {
  /**
   * Get list of available moods that the Animation can be set to
   */
  // eslint-disable-next-line class-methods-use-this
  get moods() {
    return [...moods];
  }

  /**
   * Set the mood of the AI animation
   * @param {String} mood to set the AI to
   */
  setMood(mood) {
    if (typeof mood !== 'string') {
      this.throwError(ErrorCode.NOT_A_STRING);
    }
    const command = {
      method: MethodNames.SET_SGCOM_MOOD,
      params: { mood },
    };
    this.sendCommand(ModuleNames.ANIMATION_CONTROLLER, command);
  }

  /**
   * Set the scale of the animation, usually between 0 and 2.
   * The greater the number, the greater facial movements.
   * @param {Number} scale scale of the animation
   */
  setScale(scale = 1) {
    const val = Number(scale);
    if (Number.isNaN(val)) {
      this.throwError(ErrorCode.NOT_A_NUMBER);
    }
    const command = {
      method: MethodNames.SET_SGCOM_SCALE,
      params: [val],
    };
    this.sendCommand(ModuleNames.ANIMATION_CONTROLLER, command);
  }

  /**
   * Set the frequency of the animation, usually between 0 and 2.
   * The greater the number, the lesser the time one animation plays.
   * @param {Number} frequency animation change frequency
   */
  setFrequency(frequency = 1) {
    const val = Number(frequency);
    if (Number.isNaN(val)) {
      this.throwError(ErrorCode.NOT_A_NUMBER);
    }
    const command = {
      method: MethodNames.SET_SGCOM_FREQ,
      params: [val],
    };
    this.sendCommand(ModuleNames.ANIMATION_CONTROLLER, command);
  }

  /**
   * Set the speed of the animation, usually between 0 and 2.
   * The greater the number, the faster the animation.
   * @param {Number} speed animation speed
   */
  setSpeed(speed = 1) {
    const val = Number(speed);
    if (Number.isNaN(val)) {
      this.throwError(ErrorCode.NOT_A_NUMBER);
    }
    const command = {
      method: MethodNames.SET_SGCOM_SPEED,
      params: [val],
    };
    this.sendCommand(ModuleNames.ANIMATION_CONTROLLER, command);
  }
}
